import React from 'react'
import { Box, Text, Flex } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

const About = props => {

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='The Blogdoang'
            subheader='A simple personal blog'
          />
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section>
                <Text variant='p'>
                  Blogdoang is a free blog tutorial and resource site for
                  Everyone who interested in Cloud, Site technology. The
                  Blogdoang was founded in 2021. The main goal of this site is
                  to provide quality tutorials, tips, tricks, hacks, and other
                  technology resources that allows everyone to improve their
                  skills and knowledge. Contact Us: info@blogdoang.com
                </Text>
                <Text variant='p'>
                  Contact Us: info@blogdoang.com
                </Text>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

export default About;